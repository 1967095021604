import { DefaultOptions } from 'react-query'

export const defaultQueryClientConfig: DefaultOptions = {
  queries: {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: false,
  },
}
