import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Box, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useReportList } from '../../../reports/services/ReportsHooks'
import ReportPlaceholder from '../../../reports/viewer/ReportPlaceholder'
import ReportViewer from '../../../reports/viewer/ReportViewer'
import { Report } from '../../../types'

const useStyles = makeStyles(() => ({
  reportItem: {
    height: '100%',
    position: 'relative',
    '&:hover .openInNew-icon': {
      opacity: 1,
    },
  },
}))

const Reports: React.FC = () => {
  const { t } = useTranslation()
  const styles = useStyles()
  const { reports, isLoading, error } = useReportList()

  if (isLoading) {
    return <div>{t('reports.loading')}</div>
  }

  if (error) {
    return <div>{t('reports.errorLoading')}</div>
  }

  const filteredReports = reports?.filter((report) => report.name.includes('Sawing') || report.name.includes('Sciage'))

  if (!filteredReports || filteredReports.length === 0) {
    return <ReportPlaceholder />
  }

  const handleOpenInNewTab = (report: Report) => {
    const reportUrl = `/reports/${report.workspaceId}/${report.id}`
    window.open(reportUrl, '_blank')
  }

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          border: '1px solid #ccc',
          borderTop: 'none',
        }}
      >
        {filteredReports.map((report) => (
          <div key={report.id} className={styles.reportItem}>
            <ReportViewer
              reportId={report.id}
              workspaceId={report.workspaceId}
              settings={{ navContentPaneEnabled: false }}
            />
            <IconButton
              onClick={() => handleOpenInNewTab(report)}
              sx={{
                position: 'absolute',
                top: 10,
                right: 10,
                opacity: 0,
                transition: 'opacity 0.3s',
                '&:hover': {
                  opacity: 1,
                },
              }}
              className="openInNew-icon"
            >
              <OpenInNewIcon fontSize="inherit" />
            </IconButton>
          </div>
        ))}
      </Box>
    </Box>
  )
}

export default Reports
