import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import React, { FunctionComponent } from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { BrowserRouter, useNavigate } from 'react-router-dom'

import App from './App'
import { CustomNavigationClient, msalConfig } from './authConfig'
import './i18n'
import './index.css'
import { defaultQueryClientConfig } from './queryClientConfig'
import reportWebVitals from './reportWebVitals'
import store from './store/store'
import theme from './theme'

const queryClient = new QueryClient({ defaultOptions: defaultQueryClientConfig })

const msalInstance = new PublicClientApplication(msalConfig)

const MsalConfigurationProvider: FunctionComponent = ({ children }) => {
  const navigate = useNavigate()
  const navClient = new CustomNavigationClient(navigate)
  msalInstance.setNavigationClient(navClient)

  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>
}

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <MsalConfigurationProvider>
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <App />
            </ThemeProvider>
          </Provider>
        </MsalConfigurationProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

reportWebVitals()
