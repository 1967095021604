import EmailIcon from '@mui/icons-material/Email'
import EventIcon from '@mui/icons-material/Event'
import HelpIcon from '@mui/icons-material/Help'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import PhoneIcon from '@mui/icons-material/Phone'
import { Box, Button, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ModalContainer from './ModalContainer'

const NeedHelp = () => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState(false)

  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  const handleSubmit = () => {
    setOpenModal(false)
  }

  return (
    <Box
      sx={{
        padding: 1,
        borderRadius: 1,
        boxShadow: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Typography variant="subtitle2" component="div">
        {t('help.needHelp')}
      </Typography>
      <Button
        startIcon={<MenuBookIcon />}
        variant="contained"
        color="primary"
        href="https://help.pmpsolutions.ca"
        target="_blank"
        fullWidth
        sx={{ fontSize: '0.7rem' }}
      >
        {t('help.helpCenter')}
      </Button>
      <Button
        startIcon={<HelpIcon />}
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleOpenModal}
        sx={{ fontSize: '0.7rem' }}
      >
        {t('help.supportRequests')}
      </Button>
      <ModalContainer open={openModal} onClose={handleCloseModal} onSubmit={handleSubmit} />
      <Button
        startIcon={<EventIcon />}
        variant="contained"
        color="primary"
        href="https://outlook.office365.com/book/PMPSolutions4@pmpsolutions.ca/"
        target="_blank"
        fullWidth
        sx={{ fontSize: '0.7rem' }}
      >
        {t('help.bookMeeting')}
      </Button>
      <Button
        startIcon={<EmailIcon />}
        variant="contained"
        color="primary"
        href="mailto:support@pmpsolutions.ca"
        fullWidth
        sx={{ fontSize: '0.7rem' }}
      >
        {t('help.email')}
      </Button>

      <Box display="flex" alignItems="center">
        <PhoneIcon style={{ color: 'black' }} />
        <Typography sx={{ fontSize: '0.7rem' }} style={{ marginLeft: 8 }}>
          581-891-7677
        </Typography>
      </Box>

      <Typography variant="body2" component="div">
        <a href="https://pmpsolutions.ca" target="_blank" rel="noopener noreferrer">
          {t('help.visit')}
        </a>
      </Typography>
    </Box>
  )
}

export default NeedHelp
