import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, TextField, Typography, Grid, IconButton, CircularProgress, Backdrop } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { attachFiles, createZohoTicket } from '../services/apiService'
import { useAppSelector } from '../store/hooks'
import { RootState } from '../store/store'
import type { SupportTicketModalProps, FormDataCustom } from '../types'

const SupportTicketModal: React.FC<SupportTicketModalProps> = ({ onSubmit, onClose }) => {
  const isAuthenticated = useAppSelector((state: RootState) => state.auth.isAuthenticated)
  const userDetails = useAppSelector((state: RootState) => state.auth.userDetails)
  const accessToken = useAppSelector((state: RootState) => state.auth.accessToken)
  const { t } = useTranslation()

  const [formData, setFormData] = useState<FormDataCustom>({
    name: '',
    email: '',
    millName: '',
    phone: '',
    title: '',
    problemDetail: '',
    files: [],
  })

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [descriptionError, setDescriptionError] = useState(false)

  useEffect(() => {
    if (isAuthenticated && userDetails) {
      setFormData((prevState) => ({
        ...prevState,
        name: userDetails.fullName,
        email: userDetails.email,
      }))
    }
  }, [userDetails, isAuthenticated])

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files)
      setFormData((prevState) => ({
        ...prevState,
        files: [...prevState.files, ...newFiles],
      }))
    }
  }

  const handleProblemDetailChange = (value: string) => {
    setFormData((prevState) => ({ ...prevState, problemDetail: value }))
    setDescriptionError(!value)
  }

  const handleDeleteFile = (index: number) => {
    setFormData((prevState) => ({
      ...prevState,
      files: prevState.files.filter((_, i) => i !== index),
    }))
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setError(null)

    if (!formData.problemDetail) {
      setDescriptionError(true)
      return
    }

    setLoading(true)

    try {
      if (accessToken) {
        const ticketId = await createZohoTicket(accessToken, formData)
        if (formData.files.length > 0 && formData.files) {
          await attachFiles(accessToken, ticketId, formData.files)
        }
        onSubmit(formData)
        onClose()
      }
    } catch (error) {
      console.error('Error creating ticket:', error)
      setError(t('supportForm.errorMessage') || 'An error occurred while submitting the ticket.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ margin: 'auto', p: 3 }}>
      <Backdrop open={loading} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Typography variant="h6" gutterBottom>
        {t('supportForm.title')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <TextField
            fullWidth
            label={t('supportForm.name')}
            name="name"
            value={formData.name}
            onChange={handleTextChange}
            required
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label={t('supportForm.email')}
            name="email"
            value={formData.email}
            onChange={handleTextChange}
            required
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label={t('supportForm.millName')}
            name="millName"
            value={formData.millName}
            onChange={handleTextChange}
            required
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label={t('supportForm.phone')}
            name="phone"
            value={formData.phone}
            onChange={handleTextChange}
            sx={{ mb: 2 }}
          />

          <Button
            variant="contained"
            component="label"
            sx={{ mb: 2, textAlign: 'left', color: 'black', backgroundColor: 'white' }}
          >
            {t('supportForm.addFiles')}
            <input type="file" hidden multiple onChange={handleFileChange} />
          </Button>

          {formData.files.map((file, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 2,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                variant="body1"
                sx={{ mr: 2, flexShrink: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}
                title={file.name}
              >
                {file.name}
              </Typography>
              <IconButton onClick={() => handleDeleteFile(index)} color="secondary">
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
        </Grid>
        <Grid item xs={12} md={7}>
          <TextField
            fullWidth
            label={t('supportForm.title')}
            name="title"
            value={formData.title}
            onChange={handleTextChange}
            required
            sx={{ mb: 2 }}
          />
          <ReactQuill
            value={formData.problemDetail}
            onChange={handleProblemDetailChange}
            placeholder={t('supportForm.details')}
            modules={{
              toolbar: [
                [{ header: '1' }, { header: '2' }, { font: [] }],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['bold', 'italic', 'underline'],
                ['image'],
                ['clean'],
              ],
              clipboard: {
                matchVisual: false,
              },
            }}
            formats={['header', 'font', 'list', 'bullet', 'bold', 'italic', 'underline', 'image']}
            style={{ height: '79%', maxHeight: '400px' }}
          />
          {descriptionError && (
            <Typography variant="body2" color="error">
              {t('supportForm.descriptionRequired')}
            </Typography>
          )}
        </Grid>
      </Grid>
      {error && (
        <Typography variant="body2" color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 7 }}>
        <Button variant="text" onClick={onClose} sx={{ color: 'black' }}>
          {t('buttons.cancel')}
        </Button>
        <Button type="submit" variant="contained">
          {t('buttons.submit')}
        </Button>
      </Box>
    </Box>
  )
}

export default SupportTicketModal
