import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'

import { ReactComponent as PaginatedReportIcon } from '../../assets/images/PaginatedReportIcon.svg'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0.6,
  },
  icon: {
    marginBottom: 5,
  },
  title: {
    marginBottom: 10,
  },
}))

export default function ReportPlaceholder() {
  const styles = useStyles()

  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <PaginatedReportIcon height={60} width={60} className={styles.icon} />
      <Typography align={'center'} variant="h5" className={styles.title}>
        {t('reports.noReportSelected')}
      </Typography>
      <Typography align={'center'}>{t('reports.pleaseSelectAReport')}</Typography>
    </div>
  )
}
