import { NorthWest } from '@mui/icons-material'

export const translation = {
  logout: 'Logout',
  userSettings: 'View my account',
  notConnected: 'Not connected',
  otherLanguage: 'Français',
  tabTitle: 'PMP Solutions portal',
  buttons: {
    cancel: 'Cancel',
    submit: 'Submit',
  },
  reports: {
    loading: 'Loading...',
    errorLoading: 'Error loading reports',
    pleaseSelectAReport: 'Please select a report to display.',
    reportListTitle: 'Power BI Reports',
    noReportSelected: 'No report selected',
  },
  help: {
    needHelp: 'Need help?',
    helpCenter: 'Help center',
    supportRequests: 'Support / Requests',
    bookMeeting: 'Book a meeting',
    email: 'Email us',
    visit: 'Visit pmpsolutions.ca',
  },
  supportForm: {
    name: 'Name',
    email: 'Email',
    millName: 'Mill Name',
    phone: 'Telephone',
    addFiles: 'Add Files',
    title: 'Title',
    details: 'Describe your problem. Add details so we can help you more efficiently.',
    description: 'Description',
    descriptionRequired: 'Description is required',
    errorMessage: 'An error occurred while submitting the ticket.',
  },
  dashboards: {
    refreshEvery5Minutes:
      "The dashboards are refreshed every 5 minutes, even without a connection to the mill's local network",
    dashboards: 'Dashboards',
    openLiveDashboard: 'Open live dashboard',
  },
  products: {
    myProducts: 'My products',
    otherProducts: 'Other products',
    inConstruction: 'This page is under construction',
  },
  resourceReports: {
    reports: 'Reports',
    selectEquipment: 'Select Equipment',
    browseAllReports: 'Browse all available reports',
    shift: 'Shift:',
    day: 'Day:',
    current: 'Current',
    last: 'Last',
    today: 'Today',
    week: 'Week:',
    month: 'Month:',
    year: 'Year:',
    yesterday: 'Yesterday',
    currentFiscal: 'Current Fiscal',
    lastFiscal: 'Last Fiscal',
    fiscal: 'Fiscal',
    unavailable: 'Report unavailable',
  },
  tools: {
    tools: 'Tools',
    downtimeDocumentation: 'Downtimes documentation',
    editGaugeLimits: 'Edit operational limits',
    reportsPortal: 'Reports Portal',
  },

  connection: {
    dashboardAndToolsNotAvailable:
      'Live dashboards and tools are only available when you are connected to your mill network',
  },
  userSettingsPage: {
    title: 'User Settings',
    phoneNumber: 'Phone Number:',
    organizationName: 'Organization Name:',
    email: 'Email:',
    name: 'User Name:',
    password: 'Change your password',
    here: 'here',
    save: 'Save Changes',
    saving: 'Saving...',
    cancel: 'Cancel',
    failSave: 'Failed to save details',
    successSave: 'Details saved successfully',
    invalidName: 'Invalid name',
    invalidPhone: 'Invalid phone number',
  },
  updateAlert: {
    newVersion: 'A new version of the application is available. The page will refresh in ',
    seconds: ' seconds.',
    now: 'Refresh now',
  },
}
