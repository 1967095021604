import { ArrowDropDown } from '@mui/icons-material'
import { MenuItem, Menu, IconButton } from '@mui/material'
import React, { useState } from 'react'

interface MenuItemProp {
  action: () => void
  title: string
}

interface Props {
  menuItems: MenuItemProp[]
}

export function PopoverMenu({ menuItems }: Props): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (action?: MenuItemProp['action']) => () => {
    setAnchorEl(null)
    if (action) action()
  }

  return (
    <>
      <IconButton aria-controls="profile-menu" aria-haspopup="true" onClick={handleClick} color="inherit">
        <ArrowDropDown color="inherit" />
      </IconButton>
      <Menu id="profile-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose()}>
        {menuItems.map(({ action, title }) => (
          <MenuItem key={title} onClick={handleClose(action)}>
            {title}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
