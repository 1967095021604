import { UnauthorizedError } from '../../auth/UnauthorizedError'
import { NotFoundError } from '../../errors/NotFoundError'
import { Report, ReportParams } from '../../types'

export async function fetchReportParams(
  accessToken: string,
  workspaceId: string,
  reportId: string,
): Promise<ReportParams> {
  const url = new URL(`reports/${workspaceId}/${reportId}`, process.env.REACT_APP_BACKEND_URL)
  return await fetchAsJson(url, accessToken)
}

export async function fetchReports(accessToken: string): Promise<Report[]> {
  const url = new URL('reports', process.env.REACT_APP_BACKEND_URL)
  return await fetchAsJson(url, accessToken)
}

async function fetchAsJson(url: URL, accessToken: string) {
  const response = await fetch(url.href, {
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
    }),
  })
  await handleResponseCode(response)
  return await response.json()
}

async function handleResponseCode(response: Response) {
  switch (response.status) {
    case 401:
      throw new UnauthorizedError()
    case 404:
      throw new NotFoundError(await response.json())
    case 200:
      return
    default:
      throw new Error(`Unknown error: ${await response.text()}`)
  }
}
