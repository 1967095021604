import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AuthState, UserDetails, NovuSubscriberInfo, ProductLicense, Organization, AddressCandidate } from '../types'

const initialState: AuthState = {
  isAuthenticated: false,
  isReady: false,
  name: undefined,
  accessToken: undefined,
  novuSubscriberInfo: undefined,
  productLicenses: [],
  userDetails: undefined,
  organization: undefined,
  addressCandidate: [],
  workingAddress: undefined,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload
    },
    setReady: (state, action: PayloadAction<boolean>) => {
      state.isReady = action.payload
    },
    setName: (state, action: PayloadAction<string | undefined>) => {
      state.name = action.payload
    },
    setAccessToken: (state, action: PayloadAction<string | undefined>) => {
      state.accessToken = action.payload
    },
    setNovuSubscriberInfo: (state, action: PayloadAction<NovuSubscriberInfo | undefined>) => {
      state.novuSubscriberInfo = action.payload
    },
    setProductLicenses: (state, action: PayloadAction<ProductLicense[] | undefined>) => {
      state.productLicenses = action.payload
    },
    setUserDetails: (state, action: PayloadAction<UserDetails | undefined>) => {
      state.userDetails = action.payload
    },
    setOrganization: (state, action: PayloadAction<Organization | undefined>) => {
      state.organization = action.payload
    },
    setAddressCandidate: (state, action: PayloadAction<AddressCandidate[] | undefined>) => {
      state.addressCandidate = action.payload
    },
    setWorkingAddress: (state, action: PayloadAction<string>) => {
      state.workingAddress = action.payload
    },
    clearAuth: (state) => {
      state.isAuthenticated = false
      state.isReady = false
      state.name = undefined
      state.accessToken = undefined
      state.novuSubscriberInfo = undefined
      state.productLicenses = []
      state.userDetails = undefined
      state.organization = undefined
      state.addressCandidate = []
    },
  },
})

export const {
  setAuthenticated,
  setReady,
  setName,
  setAccessToken,
  setNovuSubscriberInfo,
  setProductLicenses,
  setUserDetails,
  setOrganization,
  setAddressCandidate,
  clearAuth,
  setWorkingAddress,
} = authSlice.actions

export default authSlice.reducer
