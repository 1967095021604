import BarChartIcon from '@mui/icons-material/BarChart'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {
  List,
  ListItemButton,
  ListItemText,
  CircularProgress,
  Box,
  ClickAwayListener,
  ListItemIcon,
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, Link } from 'react-router-dom'

import { ReactComponent as PaginatedReportIcon } from '../assets/images/PaginatedReportIcon.svg'
import { useAppSelector } from '../store/hooks'
import { useReportList } from './services/ReportsHooks'
import SecondaryDrawer from './viewer/SecondaryDrawer'

const ReportsContainer = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const organization = useAppSelector((state) => state.auth.organization)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const { reports, isLoading } = useReportList()

  const handleClickOpen = () => {
    setDrawerOpen(true)
  }

  const handleClose = () => {
    setDrawerOpen(false)
  }

  const handleReportsClick = (event: React.MouseEvent) => {
    handleClickOpen()
    if (location.pathname.startsWith('/reports') && location.pathname !== '/reports') {
      event.preventDefault()
      handleClickOpen()
    }
  }

  if (!organization?.isProductViewActivated) {
    return (
      <Box>
        <List>
          <ListItemButton onClick={handleReportsClick} selected={location.pathname === '/reports'}>
            <ListItemText primary={t('reports.reportListTitle')} />
          </ListItemButton>
          {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                marginTop: 50,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress color="secondary" size={75} />
            </Box>
          ) : (
            reports?.map((report) => (
              <ListItemButton
                selected={location.pathname.includes(report.id)}
                component={Link}
                to={`/reports/${report.workspaceId}/${report.id}`}
                key={report.id}
              >
                <ListItemIcon>
                  {report.reportType === 'PaginatedReport' ? (
                    <PaginatedReportIcon />
                  ) : (
                    <BarChartIcon htmlColor="black" />
                  )}
                </ListItemIcon>
                <ListItemText primary={report.name} />
              </ListItemButton>
            ))
          )}
        </List>
      </Box>
    )
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box>
        <List>
          <ListItemButton onClick={handleReportsClick} selected={location.pathname === '/reports'}>
            <ListItemText primary={t('reports.reportListTitle')} />
            <ChevronRightIcon />
          </ListItemButton>
        </List>
        <SecondaryDrawer open={drawerOpen} onClose={handleClose} />
      </Box>
    </ClickAwayListener>
  )
}

export default ReportsContainer
