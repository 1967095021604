import { Box, Typography, List, ListItemButton, ListItemIcon } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import WeDryImage from '../assets/images/WeDry.png'
import WePlaneImage from '../assets/images/WePlane.png'
import WeSawImage from '../assets/images/WeSaw.png'
import WeScheduleImage from '../assets/images/WeSchedule.png'
import WeTrackImage from '../assets/images/WeTrack.png'
import '../assets/style/style.css'
import { useAppSelector } from '../store/hooks'

const products = [
  { id: 1, licenseName: 'WeSaw', imageUrl: WeSawImage, link: '/WeSaw' },
  { id: 2, licenseName: 'WePlane', imageUrl: WePlaneImage, link: '/WePlane' },
  { id: 3, licenseName: 'WeTrack', imageUrl: WeTrackImage, link: '/WeTrack' },
  { id: 4, licenseName: 'WeSchedule', imageUrl: WeScheduleImage, link: '/WeSchedule' },
  { id: 5, licenseName: 'WeDry', imageUrl: WeDryImage, link: '/WeDry' },
]

const ProductList = () => {
  const { t } = useTranslation()
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated)
  const productLicenses = useAppSelector((state) => state.auth.productLicenses)
  const [myProducts, setMyProducts] = useState<typeof products>([])
  const [otherProducts, setOtherProducts] = useState<typeof products>([])
  const location = useLocation()
  const currentPath = location.pathname
  const browserLanguage = (navigator.language || 'en').slice(0, 2)
  const listItemTextStyles = {
    margin: '0',
    padding: '8px 16px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1rem',
    lineHeight: '1.5',
    fontWeight: '400',
    letterSpacing: '0.00938em',
  }
  useEffect(() => {
    if (isAuthenticated && productLicenses) {
      const getUserProducts = async () => {
        const myProducts = products.filter((product) => {
          const matchingLicense = productLicenses.find((licenseObject) => {
            return licenseObject.licenseName === product.licenseName
          })
          return !!matchingLicense
        })

        const otherProducts = products.filter(
          (product) => !myProducts.some((myProduct) => myProduct.licenseName === product.licenseName),
        )

        setMyProducts(myProducts)
        setOtherProducts(otherProducts)
      }
      getUserProducts()
    }
  }, [isAuthenticated, productLicenses])

  const renderProducts = (productList: typeof products, isMyProducts: boolean): JSX.Element => (
    <List
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '0px',
        paddingBottom: '0px',
        width: '100%',
      }}
    >
      {productList.map((product) => {
        const isSelected = currentPath === product.link
        const handleClick = () => {
          if (!isMyProducts) {
            const url = `https://www.pmpsolutions.ca/${browserLanguage}/products/pmp-${product.licenseName}`
            window.open(url, '_blank')
          }
        }

        return (
          <List sx={{ width: '100%' }} key={product.id}>
            <ListItemButton
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: 0,
                paddingTop: '10px',
                paddingBottom: '10px',
              }}
              selected={isSelected}
              component={isMyProducts ? Link : 'div'}
              to={isMyProducts ? product.link : undefined}
              onClick={handleClick}
            >
              <ListItemIcon
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '45%',
                }}
              >
                <img src={product.imageUrl} alt={product.licenseName} />
              </ListItemIcon>
            </ListItemButton>
          </List>
        )
      })}
    </List>
  )

  return (
    <Box>
      <Typography variant="subtitle2" sx={listItemTextStyles}>
        {t('products.myProducts')}
      </Typography>
      {renderProducts(myProducts, true)}
      <Typography variant="subtitle2" sx={listItemTextStyles}>
        {t('products.otherProducts')}
      </Typography>
      {renderProducts(otherProducts, false)}
    </Box>
  )
}

export default ProductList
