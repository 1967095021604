import axios from 'axios'
import JSONbig from 'json-bigint'

import { apiConfig } from '../authConfig'
import {
  NovuSubscriberInfo,
  ProductLicense,
  UserDetails,
  Organization,
  Dashboard,
  AddressCandidate,
  ResourceReport,
  FormDataCustom,
} from '../types'

const apiClient = axios.create({
  baseURL: apiConfig.webApiUri,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const fetchNovuSubscriberInfo = async (accessToken: string): Promise<NovuSubscriberInfo> => {
  const response = await apiClient.get<NovuSubscriberInfo>('/users/novuSubscriberInfo', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  return response.data
}

export const fetchUserDetails = async (accessToken: string): Promise<UserDetails> => {
  const response = await apiClient.get<UserDetails>('/users/details', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  return response.data
}

export const fetchProductLicenses = async (accessToken: string): Promise<ProductLicense[]> => {
  const response = await apiClient.get<ProductLicense[]>('/productLicenses', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  return response.data
}

export const fetchOrganization = async (accessToken: string): Promise<Organization> => {
  const response = await apiClient.get<Organization>('/organizations/user', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  return response.data
}

export const fetchResourceReport = async (accessToken: string): Promise<ResourceReport[]> => {
  const response = await apiClient.get<ResourceReport[]>('/resourcereport?productLicenseName=WeSaw', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  return response.data
}

export const fetchDashboards = async (accessToken: string): Promise<Dashboard[]> => {
  const response = await apiClient.get<Dashboard[]>('/Dashboards?LicenseName=WeSaw', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  return response.data
}

export const fetchAddressCandidate = async (accessToken: string): Promise<AddressCandidate[]> => {
  const response = await apiClient.get<AddressCandidate[]>('/PmpServerAddressCandidates/user', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  return response.data
}

export const createZohoTicket = async (accessToken: string, formData: FormDataCustom) => {
  const [firstName, lastName] = formData.name.split(' ', 2)

  const description = '\nMill Name : ' + formData.millName + '\n' + formData.problemDetail

  const ticket = {
    subject: formData.title,
    departmentId: '220064000000006907',
    contact: {
      email: formData.email,
      phone: formData.phone,
      firstName: firstName,
      lastName: lastName,
    },
    description: description,
  }

  const response = await apiClient.post('/ZohoTicket/createTicket', ticket, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    transformResponse: (data) => JSONbig.parse(data),
  })
  return response.data
}

export const attachFiles = async (accessToken: string, ticketId: string, files: File[]) => {
  const formDataToSend = new FormData()
  formDataToSend.append('ticketId', ticketId)

  files.forEach((file) => {
    formDataToSend.append('Files', file)
  })

  await apiClient.post('/ZohoTicket/addFiles', formDataToSend, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

export const updateUserName = async (accessToken: string, name: string) => {
  const updatedUser = { fullName: name }

  await apiClient.put('/users/name', updatedUser, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

export const updateUserPhone = async (accessToken: string, phone: string) => {
  const updatedUser = { phoneNumber: phone }

  await apiClient.put('/users/phone', updatedUser, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

export const updateUserLanguage = async (accessToken: string, language: string) => {
  const updatedUser = { language: language }

  await apiClient.put('/users/language', updatedUser, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })
}
