interface NotFoundResponse {
  Detail: string
}

export class NotFoundError extends Error {
  constructor({ Detail: message }: NotFoundResponse) {
    super(message)
    this.name = 'NotFoundError'
  }
}
