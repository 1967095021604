// import { BlobServiceClient, StorageSharedKeyCredential } from '@azure/storage-blob'
import { Select, MenuItem, FormControl, Link, SelectChangeEvent, Box, Typography, Grid, Tooltip } from '@mui/material'
import React, { useState, useEffect, useMemo, Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { fetchResourceReport } from '../../../services/apiService'
import { useAppSelector } from '../../../store/hooks'
import theme from '../../../theme'
import { ResourceReport } from '../../../types'

const ReportsComponent: React.FC<{ isConnected: boolean }> = ({ isConnected }) => {
  const { t } = useTranslation()
  const accessToken = useAppSelector((state) => state.auth.accessToken)
  const serverIP = useAppSelector((state) => state.auth.workingAddress)
  const [reportsData, setReportsData] = useState<ResourceReport[]>([])
  const [selectedResourceId, setSelectedResourceId] = useState<string>('')

  useEffect(() => {
    const loadResourceReport = async () => {
      if (accessToken) {
        try {
          const data = await fetchResourceReport(accessToken)
          setReportsData(data as ResourceReport[])
        } catch (error) {
          console.error('Error fetching report data:', error)
        }
      }
    }

    loadResourceReport()
  }, [])

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedResourceId(event.target.value as string)
  }
  const selectedReport = useMemo(
    () => reportsData.find((report) => report.resourceId === selectedResourceId),
    [reportsData, selectedResourceId],
  )

  const [disabledLinks, setDisabledLinks] = useState<{ [key: string]: boolean }>({})

  const isLinkDisabled = async (link: string) => {
    if (!link || link === '') return true

    try {
      const response = await fetch(link, {
        method: 'HEAD',
      })
      if (!response.ok) {
        return true
      }
      return false
    } catch (error) {
      console.error('Error checking link existence:', error)
      return true
    }
  }

  const checkLink = async (href: string) => {
    const disabled = await isLinkDisabled(href)
    setDisabledLinks((prev) => ({
      ...prev,
      [href]: disabled,
    }))
  }

  const renderLink = (href: string, label: string) => {
    useEffect(() => {
      checkLink(href)
    }, [href])

    const disabled = disabledLinks[href] || false

    return (
      <Tooltip title={disabled ? t(`resourceReports.unavailable`) : ''} placement="right">
        <Link
          href={disabled ? undefined : href}
          target={!disabled ? '_blank' : undefined}
          rel={!disabled ? 'noopener noreferrer' : undefined}
          sx={{
            textDecoration: !disabled ? 'underline' : 'none',
            color: !disabled ? 'inherit' : 'grey',
            cursor: !disabled ? 'pointer' : 'not-allowed',
          }}
        >
          {label}
        </Link>
      </Tooltip>
    )
  }

  const reportSections = useMemo(
    () => [
      {
        title: t('resourceReports.shift'),
        links: [
          { href: selectedReport?.currentShift || '', label: t('resourceReports.current') },
          { href: selectedReport?.lastShift || '', label: t('resourceReports.last') },
        ],
      },
      {
        title: t('resourceReports.day'),
        links: [
          { href: selectedReport?.today || '', label: t('resourceReports.today') },
          { href: selectedReport?.yesterday || '', label: t('resourceReports.yesterday') },
        ],
      },
      {
        title: t('resourceReports.week'),
        links: [
          { href: selectedReport?.currentWeek || '', label: t('resourceReports.current') },
          { href: selectedReport?.lastWeek || '', label: t('resourceReports.last') },
        ],
      },
      {
        title: t('resourceReports.month'),
        links: [
          { href: selectedReport?.currentMonth || '', label: t('resourceReports.current') },
          { href: selectedReport?.lastMonth || '', label: t('resourceReports.last') },
        ],
        fiscalLinks: [
          { href: selectedReport?.currentFiscalMonth || '', label: t('resourceReports.currentFiscal') },
          { href: selectedReport?.lastFiscalMonth || '', label: t('resourceReports.lastFiscal') },
        ],
      },
      {
        title: t('resourceReports.year'),
        links: [
          { href: selectedReport?.currentYear || '', label: t('resourceReports.current') },
          { href: selectedReport?.lastYear || '', label: t('resourceReports.last') },
        ],
        fiscalLinks: [
          { href: selectedReport?.currentFiscalYear || '', label: t('resourceReports.currentFiscal') },
          { href: selectedReport?.lastFiscalYear || '', label: t('resourceReports.lastFiscal') },
        ],
      },
    ],
    [selectedReport, t],
  )

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', flex: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', padding: 1 }}>
        <Typography variant="h6">{t('resourceReports.reports')}</Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 2 }}>
        <FormControl sx={{ width: '100%', background: theme.palette.background.default }}>
          <Select
            value={selectedResourceId}
            onChange={handleChange}
            displayEmpty
            renderValue={(selected) => {
              if (selected === '') {
                return <span style={{ color: 'grey' }}>{t('resourceReports.selectEquipment')}</span>
              }
              return reportsData.find((report) => report.resourceId === selected)?.displayName
            }}
          >
            <MenuItem value="">
              <span style={{ color: 'grey' }}>{t('resourceReports.selectEquipment')}</span>
            </MenuItem>
            {reportsData.map((report) => (
              <MenuItem key={report.resourceId} value={report.resourceId}>
                {report.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ m: 2 }}>
        <Grid container spacing={3} alignItems="center">
          {reportSections.map(({ title, links, fiscalLinks }, index) => (
            <Fragment key={index}>
              <Grid item xs={3.7}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={8.3}>
                <Box>
                  {links.map(({ href, label }, linkIndex) => (
                    <Fragment key={linkIndex}>
                      {linkIndex > 0 && ' | '}
                      {renderLink(href, label)}
                    </Fragment>
                  ))}
                </Box>
                {fiscalLinks && (
                  <Box sx={{ mt: 1 }}>
                    {fiscalLinks.map(({ href, label }, linkIndex) => (
                      <Fragment key={linkIndex}>
                        {linkIndex > 0 && ' | '}
                        {renderLink(href, label)}
                      </Fragment>
                    ))}
                  </Box>
                )}
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </Box>

      <Box sx={{ flex: 1 }} />

      <Box sx={{ m: 2 }}>
        <Tooltip title={t('connection.dashboardAndToolsNotAvailable')} followCursor>
          <Typography variant="body1" component="span">
            <a
              href={isConnected ? `http://${serverIP}:49990/Reports` : 'javascript:void(0)'}
              target={isConnected ? '_blank' : undefined}
              rel={isConnected ? 'noopener noreferrer' : undefined}
              style={{
                textDecoration: 'underline',
                color: isConnected ? 'default' : 'grey',
                cursor: isConnected ? 'pointer' : 'not-allowed',
              }}
            >
              {t('resourceReports.browseAllReports').split(' ')[0]}
            </a>{' '}
            {t('resourceReports.browseAllReports').replace(t('resourceReports.browseAllReports').split(' ')[0], '')}
          </Typography>
        </Tooltip>
      </Box>
    </Box>
  )
}

export default ReportsComponent
