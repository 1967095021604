import { Box, Typography, Link, Tooltip } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from '../../../store/hooks'

const Tools: React.FC<{ isConnected: boolean }> = ({ isConnected }) => {
  const { t } = useTranslation()
  const serverIP = useAppSelector((state) => state.auth.workingAddress)
  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography sx={{ mr: 6, fontWeight: 'bold', fontSize: 18, color: 'white' }}>{t('tools.tools')} :</Typography>
      <Tooltip title={t('connection.dashboardAndToolsNotAvailable')} followCursor>
        <Link
          href={isConnected ? `http://${serverIP}:49989` : 'javascript:void(0)'}
          target={isConnected ? '_blank' : undefined}
          rel={isConnected ? 'noopener noreferrer' : undefined}
          sx={{
            textDecoration: 'underline',
            color: isConnected ? 'white' : 'lightgrey',
            cursor: isConnected ? 'pointer' : 'not-allowed',
            mr: 6,
          }}
        >
          {t('tools.downtimeDocumentation')}
        </Link>
      </Tooltip>
      <Tooltip title={t('connection.dashboardAndToolsNotAvailable')} followCursor>
        <Link
          href={isConnected ? `http://${serverIP}:49986/#/obeditor` : 'javascript:void(0)'}
          target={isConnected ? '_blank' : undefined}
          rel={isConnected ? 'noopener noreferrer' : undefined}
          sx={{
            textDecoration: 'underline',
            color: isConnected ? 'white' : 'lightgrey',
            cursor: isConnected ? 'pointer' : 'not-allowed',
            mr: 6,
          }}
        >
          {t('tools.editGaugeLimits')}
        </Link>
      </Tooltip>
      <Tooltip title={t('connection.dashboardAndToolsNotAvailable')} followCursor>
        <Link
          href={isConnected ? `http://${serverIP}:49990/Reports` : 'javascript:void(0)'}
          target={isConnected ? '_blank' : undefined}
          rel={isConnected ? 'noopener noreferrer' : undefined}
          sx={{
            textDecoration: 'underline',
            color: isConnected ? 'white' : 'lightgrey',
            cursor: isConnected ? 'pointer' : 'not-allowed',
            mr: 6,
          }}
        >
          {t('tools.reportsPortal')}
        </Link>
      </Tooltip>
    </Box>
  )
}
export default Tools
