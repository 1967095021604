import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import theme from '../../theme'
import Dashboards from './Dashboards/Dashboards'
import Reports from './Reports/Reports'
import SSRSReports from './SSRSReports/ReportsComponent'
import Tools from './Tools/Tools'

const WeSaw: React.FC<{ isConnected: boolean }> = ({ isConnected }) => {
  const { t } = useTranslation()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {!isConnected && (
        <Box
          sx={{
            backgroundColor: theme.palette.error.main,
            color: 'white',
            padding: '8px',
            textAlign: 'center',
            height: '5%',
          }}
        >
          <Typography variant="h6">{t('connection.dashboardAndToolsNotAvailable')}</Typography>
        </Box>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', background: '#0072CE' }}>
        <Typography variant="h6" sx={{ padding: '8px', width: '13%', fontWeight: 'bold', color: 'white' }}>
          PMP WeSaw
        </Typography>
        <Tools isConnected={isConnected} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}></Box>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Dashboards isConnected={isConnected} />
        <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
          <Box sx={{ flexBasis: '20%' }}>
            <SSRSReports isConnected={isConnected} />
          </Box>
          <Box sx={{ flexBasis: '80%' }}>
            <Reports />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default WeSaw
