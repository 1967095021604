import { Configuration, SilentRequest, NavigationClient } from '@azure/msal-browser'
import { NavigateFunction } from 'react-router-dom'

const frontendUrl = process.env.REACT_APP_FRONTEND_URL
const backendUrl = process.env.REACT_APP_BACKEND_URL
const clientId = process.env.REACT_APP_B2C_CLIENT_ID
const instanceWithHttps = process.env.REACT_APP_B2C_INSTANCE
const domain = process.env.REACT_APP_B2C_DOMAIN
const policy = process.env.REACT_APP_B2C_SIGNIN_POLICY
const novuAppIdentifier = process.env.REACT_APP_NOVU_APP_IDENTIFIER

if (!frontendUrl) throw 'Invalid configuration. Please set REACT_APP_FRONTEND_URL.'
if (!backendUrl) throw 'Invalid configuration. Please set REACT_APP_BACKEND_URL.'
if (!clientId) throw 'Invalid configuration. Please set REACT_APP_B2C_CLIENT_ID.'
if (!instanceWithHttps) throw 'Invalid configuration. Please set REACT_APP_B2C_INSTANCE.'
if (!domain) throw 'Invalid configuration. Please set REACT_APP_B2C_DOMAIN.'
if (!policy) throw 'Invalid configuration. Please set REACT_APP_B2C_SIGNIN_POLICY.'
if (!novuAppIdentifier) throw 'Invalid configuration. Please set REACT_APP_NOVU_APP_IDENTIFIER.'

const instance = instanceWithHttps.replace('https://', '')

export const msalConfig: Configuration = {
  auth: {
    clientId,
    authority: `https://${instance}/${domain}/${policy}`,
    knownAuthorities: [instance],
    redirectUri: frontendUrl,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
}

export const apiConfig = {
  b2cScopes: [`https://${domain}/pmp-portal-api/reports.read`],
  webApiUri: backendUrl,
}

/** Request configuration that adds scopes here for ID token to be used at Microsoft identity platform endpoints. */
export const loginRequest: SilentRequest = {
  scopes: apiConfig.b2cScopes,
}

export {
  /** Application identifier for Novu, set as REACT_APP_NOVU_APP_IDENTIFIER in environment variables */
  novuAppIdentifier,
}

// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/performance.md
export class CustomNavigationClient extends NavigationClient {
  private navigate: NavigateFunction

  constructor(navigate: NavigateFunction) {
    super()
    this.navigate = navigate
  }

  async navigateInternal(url: string, options: { noHistory?: boolean }): Promise<boolean> {
    if (options.noHistory) {
      this.navigate(url, { replace: true })
    } else {
      this.navigate(url)
    }
    return false
  }
}
