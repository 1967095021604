import { createTheme } from '@mui/material'

const theme = createTheme({
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#F1F1F1',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(253, 209, 6, 0.3)',
          },
          '&:hover': {
            backgroundColor: 'rgba(253, 209, 6, 0.6)',
          },
        },
      },
    },
  },
  palette: {
    background: {
      default: '#FFFFFF',
    },
    primary: {
      main: '#FDD106',
    },
    secondary: {
      main: '#2D2926',
    },
  },
})

export default theme
