import BarChartIcon from '@mui/icons-material/BarChart'
import { Drawer, Box, List, ListItemButton, ListItemIcon, ListItemText, CircularProgress } from '@mui/material'
import React from 'react'
import { Link, useParams } from 'react-router-dom'

import { ReactComponent as PaginatedReportIcon } from '../../assets/images/PaginatedReportIcon.svg'
import { useReportList } from '../services/ReportsHooks'

interface SecondaryDrawerProps {
  open: boolean
  onClose: () => void
}

const SecondaryDrawer: React.FC<SecondaryDrawerProps> = ({ open, onClose }) => {
  const { reportId } = useParams<{ reportId: string }>()
  const { reports, isLoading } = useReportList()

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        sx: {
          width: 320,
          height: '100%',
          top: 0,
          marginLeft: '275px',
          marginTop: '64px',
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
        {isLoading ? (
          <CircularProgress color="secondary" />
        ) : (
          <List>
            {reports?.map((report) => (
              <ListItemButton
                selected={reportId === report.id}
                component={Link}
                to={`/reports/${report.workspaceId}/${report.id}`}
                key={report.id}
                onClick={onClose}
              >
                <ListItemIcon>
                  {report.reportType === 'PaginatedReport' ? (
                    <PaginatedReportIcon />
                  ) : (
                    <BarChartIcon htmlColor="black" />
                  )}
                </ListItemIcon>
                <ListItemText primary={report.name} />
              </ListItemButton>
            ))}
          </List>
        )}
      </Box>
    </Drawer>
  )
}

export default SecondaryDrawer
