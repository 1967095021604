import { fetchReportParams, fetchReports } from './ReportsApi'

const queries = {
  getReportParams: {
    name: ['reports', 'getParams'],
    request: fetchReportParams,
  },
  listReports: {
    name: ['reports', 'list'],
    request: fetchReports,
  },
}

export default queries
