import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogTitle, DialogContent, Box, IconButton } from '@mui/material'
import React from 'react'

import type { ModalContainerProps, FormDataCustom } from '../types'
import SupportTicketModal from './SupportTicketModal'

const ModalContainer: React.FC<ModalContainerProps> = ({ open, onClose, onSubmit }) => {
  const handleFormSubmit = (data: FormDataCustom) => {
    onSubmit(data)
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <SupportTicketModal onSubmit={handleFormSubmit} onClose={onClose} />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ModalContainer
