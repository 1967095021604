import React from 'react'
import { useParams } from 'react-router-dom'

import ReportViewer from './ReportViewer'

const ReportViewerWrapper: React.FC = () => {
  const { workspaceId, reportId } = useParams<{ workspaceId: string; reportId: string }>()

  if (!workspaceId || !reportId) {
    return <div>Invalid parameters</div>
  }

  return <ReportViewer reportId={reportId} workspaceId={workspaceId} />
}

export default ReportViewerWrapper
